<template>
    <b-card-code>

        <validation-observer ref="simpleRules">
            <b-form ref="download_student_fee_collection_report">
                <b-row>
                    <b-col md="3">
                        <b-card-text>
                            <span>Select Class </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="class" rules="required">
                            <select name="classes_id" v-model="classes_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="0">All</option>
                                <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                                    {{ cls.name }}
                                </option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <b-card-text>
                            <span>Select Wing </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="wing" rules="required">
                            <select name="wing_id" v-model="wing_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="0">All</option>
                                <option v-for="(wing, key) in wings" :value="wing.id" :key="key">
                                    {{ wing.name }}
                                </option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3">
                        <b-card-text>
                            <span>Select Report Type </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="report_type_id" rules="required">
                            <select id="report_type_id" v-model="report_type_id"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="1" :key="key">Monthly</option>
                                <option :value="2" :key="key">Yearly</option>
                                <option :value="3" :key="key">By Date Range</option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 1 || report_type_id === 2">
                        <b-card-text>
                            <span>Select Year</span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="year_id" rules="required">
                            <select id="year_id" v-model="year_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="1" :key="key">2021</option>
                                <option :value="2" :key="key">2022</option>
                                <option :value="3" :key="key">2023</option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 1">
                        <b-card-text>
                            <span>Select Month </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" name="month_id" rules="required">
                            <select id="month_id" v-model="month_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                class="form-control">
                                <option :value="null">Select One</option>
                                <option :value="1" :key="key">January</option>
                                <option :value="2" :key="key">February</option>
                                <option :value="3" :key="key">March</option>

                                <option :value="4" :key="key">April</option>
                                <option :value="5" :key="key">May</option>
                                <option :value="6" :key="key">June</option>

                                <option :value="7" :key="key">July</option>
                                <option :value="8" :key="key">August</option>
                                <option :value="9" :key="key">September</option>

                                <option :value="10" :key="key">Otcober</option>
                                <option :value="11" :key="key">November</option>
                                <option :value="12" :key="key">December</option>
                            </select>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>



                    <b-col md="3" v-if="report_type_id === 3">
                        <b-card-text>
                            <span>{{ $t("Start Date") }} </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" :name="$t('report start date')" rules="required">
                            <b-form-datepicker name="rpt_start_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                                v-model="rpt_start_date" :placeholder="$t('Select start date')" class="form-control">
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <b-col md="3" v-if="report_type_id === 3">
                        <b-card-text>
                            <span>{{ $t("End Date") }} </span>
                        </b-card-text>
                        <validation-provider #default="{ errors }" :name="$t('report end date')" rules="required">
                            <b-form-datepicker name="rpt_end_date" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :date-format-options="{ year: 'numeric', month: 'numeric', day: '2-digit' }"
                                v-model="rpt_end_date" :placeholder="$t('select end date')" class="form-control">
                            </b-form-datepicker>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-col>

                    <!-- submit button -->
                    <b-col md="12" class="mt-2">
                        <b-button variant="primary" type="submit" @click.prevent="downloadTutionBill">
                            Download Report
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormDatepicker
} from "bootstrap-vue";
import { required, integer } from "@validations";
import apiCall from "@/libs/axios";
import download from "downloadjs";

export default {
    name: "reportCollection",
    components: {
        BCardCode,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText
    },
    data() {
        return {
            classes_id: null,
            wing_id: null,
            rpt_start_date: "",
            rpt_end_date: "",
            required,
            integer,
            classes: [],
            wings: [],
            report_type_id: null,
            month_id: null,
            year_id: null
        };
    },
    created() {
        this.getClassList();
    },
    methods: {
        downloadTutionBill() {
            let data = new FormData(this.$refs.download_student_fee_collection_report);
            var fee_head_id = (this.wing_id == 1) ? 1 : 3;
            data.append('fee_head_id', fee_head_id);

            apiCall
                .post("/download/student/bill/collection/report", data, {
                    responseType: "blob",
                })
                .then((response) => {
                    const content = response.headers["content-type"];
                    download(response.data, "tution_and_messing_fee_report.pdf", content);
                })
                .catch((error) => {
                    this.$toaster.error(error.response.data.message);
                });
        },
        async getClassList() {
            await apiCall
                .get("get/active/class/with/v/s/groups")
                .then((response) => {
                    this.classes = response.data;
                    this.wings = response.data[0].wings;
                })
                .catch(() => {
                    this.classes = [];
                    this.wings = [];
                });
        },
    }

};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>